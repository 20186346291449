import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeBanner from '../components/Home Banner/'
import HomeAbout from '../components/Home About'
import HomeClients from '../components/Home Clients'
import HomeSuccesses from '../components/Home Successes'

const IndexPage = () => {
    const initial_data_query = useStaticQuery(graphql`
    query {
      wpgraphql {
        page(id: "cG9zdDo4") {
          title
        }
      }
    }
  `)

  let page_title = initial_data_query.wpgraphql;
  let title = page_title.page.title;

  return(
    <div id="Homepage">
        <Layout>
            <SEO title={title}/>
        
            <div className="homepage_wrapper">
                <HomeBanner/>
                <HomeAbout/>
                <HomeClients/>
                <HomeSuccesses/>
            </div>
        </Layout>
    </div>
  )
}

export default IndexPage
