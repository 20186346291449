import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const settings = {
    rows: 2,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3500,
    loop: true,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 576,
        settings: {
            rows: 1,
            arrows: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
        }
    }]
};

const HomeClients = () => {

    // Querying and storing Data Stored in Gatsby GraphQL query.

    // const initial_data_query = useStaticQuery(graphql`
    // query {
    //     wpgraphql {
    //         client(where: {orderby: {field: DATE, order: ASC}}) {
    //             edges {
    //                 node {
    //                     slug
    //                     title
    //                     id
    //                     acf_clients {
    //                         activeClientCompanyLogo {
    //                             altText
    //                             sourceUrl
    //                         }
    //                         clientCompanyLink
    //                     }
    //                 }
    //             }
    //         }
            
    //     }
    // }
    

    // `)

    // let initial_state_data = initial_data_query.wpgraphql;


    const mergedQuery = useStaticQuery(graphql`
    query {
        wpgraphql {
            client(where: {orderby: {field: DATE, order: ASC}}) {
                edges {
                    node {
                        slug
                        title
                        id
                        acf_clients {
                            activeClientCompanyLogo {
                                altText
                                sourceUrl
                            }
                            clientCompanyLink
                        }
                    }
                }
            }
        }
    }
`);

const initial_state_data = mergedQuery.wpgraphql;


    // Storing this data as the State so that we can update the state later with Live Data
    const [existingData, updateData] = useState(initial_state_data);

    // Fetching the Live data from the created WORDPRESS API
    useEffect(() => {
        let curr_api = process.env.GATSBY_API_URL;

        fetch(`${curr_api}/graphql`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                query: `
        {
            client(where: {orderby: {field: DATE, order: ASC}}) {
                edges {
                    node {
                        slug
                        title
                        id
                        acf_clients {
                            
                            activeClientCompanyLogo {
                                altText
                                sourceUrl
                            }
                            clientCompanyLink
                        }
                    }
                }
            }
            
        }
        
        `,
            }),
        })
            .then(res => res.json())
            .then(data => updateData(data.data))
            .catch(error => console.log(`Some Error Occurred! The Error :${error}`))
    }, [])


    // Storing All Live Data in Variables to display it

    let clients = existingData.client.edges;

    return (
        <div className="clients_section">
            <div className="inner container">
                <div className="client-title">
                    <h3 className="section_title"> Clients </h3>
                    <p>We invest substantial time in assessing client requirements and accordingly devise a plan based on required competencies and technical knowledge.</p>

                </div>
                {/* <div className="logo_row flex_view_xs middle xs-hide">
                {
                    clients.map( client => {
                        console.log(client)
                        return(
                            <div className="logo_div" key={client.node.id}>
                                <div className="logo_icon">
                                    <a target="_blank" href={client.node.acf_clients.clientCompanyLink}>
                                    <img className="active" src={client.node.acf_clients.activeClientCompanyLogo.sourceUrl} alt={client.node.acf_clients.activeClientCompanyLogo.altText} />
                                    </a>
                                </div>

                            </div>

                        )
                    } )
                }
                </div> */}

                <div className="logo_row_xs">
                    <Slider {...settings}>
                        {
                            clients.map(client => {
                                return (
                                    <div className="logo_div" key={client.node.id}>
                                        <div className="logo_icon">
                                            <img className="active" src={client.node.acf_clients.activeClientCompanyLogo.sourceUrl} alt={client.node.acf_clients.activeClientCompanyLogo.altText} />
                                        </div>

                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default HomeClients

