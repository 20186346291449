import React, { useState, useEffect } from "react"
import {Link, useStaticQuery, graphql } from "gatsby"

const HomeAbout = () => {

    // Querying and storing Data Stored in Gatsby GraphQL query.

    const initial_data_query = useStaticQuery(graphql`
    query {
        wpgraphql {
            pages(where: { title: "Homepage" }) {
                edges {
                    node {
                        title
                        uri
                        slug
                        id
                        acf_home_About {
                            column1Title
                            column1Description
                            column2Title
                            column2Description
                            column3Title
                            column3Description
                        }
                    }
                }
            }
        }
    }
    

    `)

    let initial_state_data = initial_data_query.wpgraphql;

    // Storing this data as the State so that we can update the state later with Live Data
    const [existingData, updateData] = useState(initial_state_data);

    // Fetching the Live data from the created WORDPRESS API
    useEffect(() => {
    let curr_api = process.env.GATSBY_API_URL;

    fetch(`${curr_api}/graphql`, {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
    },
    body: JSON.stringify({
        query: `
        {
            pages(where: { title: "Homepage" }) {
                edges {
                    node {
                        title
                        uri
                        slug
                        id
                        acf_home_About {
                            column1Title
                            column1Description
                            column2Title
                            column2Description
                            column3Title
                            column3Description
                        }
                    }
                }
            }
        }
        
        `,
        }),
    })
    .then(res => res.json())
    .then(data => updateData(data.data))
    .catch(error => console.log(`Some Error Occurred! The Error :${error}`))
    }, [])


    // Storing All Live Data in Variables to display it

    let aboutCols = existingData.pages.edges[0].node.acf_home_About;
     
    return (
        <div className="about_section">
            <div className="inner flex_view_xs ">
                <div className="col-xs-12 col-sm-4">
                    <div className="col col_1 text-center">
                        <Link to="/about#expertise-section">
                        <p className="title"> { aboutCols.column1Title } </p>
                        <hr/>
                        <p className="about-paragraph-content"> { aboutCols.column1Description } </p>

                        <div className="arrow_icon">
                            <svg viewBox="0 0 512 512">
                                <path
                                    d="M256 512c141.2 0 256-114.8 256-256S397.2 0 256 0 0 114.8 0 256s114.8 256 256 256zm-57.7-347.6c-8.3-8.3-8.3-21.8 0-30.2 4.2-4.2 9.6-6.3 15.1-6.3s10.9 2.1 15.1 6.3l106.7 106.7c8.3 8.3 8.3 21.8 0 30.2L228.4 377.7c-8.3 8.3-21.8 8.3-30.2 0s-8.3-21.8 0-30.2l91.6-91.6-91.5-91.5z"
                                />
                            </svg>
                        </div>
                        </Link>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-4">
                    <div className="col col_2 text-center">
                    <Link to="/about#focus-section">
                        <p className="title">{ aboutCols.column2Title }</p>
                        <hr/>
                        <p className="about-paragraph-content"> { aboutCols.column2Description } </p>

                        <div className="arrow_icon">
                            <svg viewBox="0 0 512 512">
                                <path
                                    d="M256 512c141.2 0 256-114.8 256-256S397.2 0 256 0 0 114.8 0 256s114.8 256 256 256zm-57.7-347.6c-8.3-8.3-8.3-21.8 0-30.2 4.2-4.2 9.6-6.3 15.1-6.3s10.9 2.1 15.1 6.3l106.7 106.7c8.3 8.3 8.3 21.8 0 30.2L228.4 377.7c-8.3 8.3-21.8 8.3-30.2 0s-8.3-21.8 0-30.2l91.6-91.6-91.5-91.5z"
                                />
                            </svg>
                        </div>
                        </Link>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-4">
                    <div className="col col_3 text-center">
                    <Link to="/about#reach-section">
                        <p className="title">{ aboutCols.column3Title }</p>
                        <hr/>
                        <p className="about-paragraph-content"> { aboutCols.column3Description } </p>

                        <div className="arrow_icon">
                            <svg viewBox="0 0 512 512">
                                <path
                                    d="M256 512c141.2 0 256-114.8 256-256S397.2 0 256 0 0 114.8 0 256s114.8 256 256 256zm-57.7-347.6c-8.3-8.3-8.3-21.8 0-30.2 4.2-4.2 9.6-6.3 15.1-6.3s10.9 2.1 15.1 6.3l106.7 106.7c8.3 8.3 8.3 21.8 0 30.2L228.4 377.7c-8.3 8.3-21.8 8.3-30.2 0s-8.3-21.8 0-30.2l91.6-91.6-91.5-91.5z"                                    
                                />
                            </svg>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeAbout
