import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const HomeBanner = () => {

    // Querying and storing Data Stored in Gatsby GraphQL query.

    const initial_data_query = useStaticQuery(graphql`
    query {
        wpgraphql {
            pages(where: { title: "Homepage" }) {
                edges {
                    node {
                        title
                        uri
                        slug
                        id
                        acf_home_banner {
                            bannerTitle
                            bannerDescription
                            bannerButton {
                                target
                                title
                                url
                            }
                            homeBannerImg {
                                altText
                                sourceUrl
                            }
                        }
                    }
                }
            }
        }
    }
    

    `)

    let initial_state_data = initial_data_query.wpgraphql;

    // Storing this data as the State so that we can update the state later with Live Data
    const [existingData, updateData] = useState(initial_state_data);

    // Fetching the Live data from the created WORDPRESS API
    useEffect(() => {
        let curr_api = process.env.GATSBY_API_URL;

        fetch(`${curr_api}/graphql`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                query: `
        {
            pages(where: { title: "Homepage" }) {
                edges {
                    node {
                        title
                        uri
                        slug
                        id
                        acf_home_banner {
                            bannerTitle
                            bannerDescription
                            bannerButton {
                                target
                                title
                                url
                            }
                            homeBannerImg {
                                altText
                                sourceUrl
                            }
                        }
                    }
                }
            }
        }
        
        `,
            }),
        })
            .then(res => res.json())
            .then(data => updateData(data.data))
            .catch(error => console.log(`Some Error Occurred! The Error :${error}`))
    }, [])


    // Storing All Live Data in Variables to display it

    let bannerData = existingData.pages.edges[0].node.acf_home_banner;

    return (
        <div className="banner_section">
            <div className="inner container">
                <div className="banner_txt">
                    <h3 className="banner_title"> {bannerData.bannerTitle} </h3>
                    <p className="banner_descr"> {bannerData.bannerDescription} </p>
                    <div className="btn-logo-wrapper">
                        <Link to={bannerData.bannerButton.url} className="banner_btn btn_green"> {bannerData.bannerButton.title} </Link>
                        <div className="year-logo-wrapper">
                            <img src="https://api.tamarindsearch.com/wp-content/uploads/2024/01/year-logo-tamarind.svg"></img>
                            <span>Leading Pharma Searches
                                Globally Since 2011</span>
                        </div>
                    </div>
                </div>

                <div className="banner_img">
                    <div className="img">
                        <img src={bannerData.homeBannerImg.sourceUrl} alt={bannerData.homeBannerImg.altText} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeBanner
